import React, { useContext, useEffect } from 'react';
import { graphql } from 'gatsby';
import SEO from '../components/SEO';
import Div100vh from 'react-div-100vh';
import { AppContext } from '../AppContext';
import { LocaleContext } from '../components/Layout';
import Scrollbar from 'react-scrollbars-custom';
import Img from 'gatsby-image';
import './about.scss';

const About = ({ data }) => {
  const intro = data.prismicAbout.data.intro.html;
  const portrait =
    data.prismicAbout.data.portrait.localFile.childImageSharp.fixed;
  const alt = data.prismicAbout.data.portrait.alt;
  const content = data.prismicAbout.data.content.html;
  const title = data.prismicAbout.data.title.text;
  const seo_title = data.prismicAbout.data.seo_title.text;
  const og_image = data.prismicAbout.data.portrait.url;

  const { setTheme } = useContext(AppContext);
  const { locale } = useContext(LocaleContext);

  useEffect(() => {
    setTheme('dark');
  }, []);

  return (
    <>
      <SEO
        title={
          typeof seo_title !== 'undefined' || seo_title !== ''
            ? seo_title
            : title
        }
        lang={locale}
        image={og_image}
      ></SEO>
      <Div100vh>
        <div className="about">
          <Scrollbar momentum noScrollX maximalThumbSize={150}>
            <div className="title-container">
              <h1>{title}</h1>
            </div>
            <div className="intro-container">
              <div className="portrait-container">
                <Img
                  fixed={portrait}
                  draggable={false}
                  imgStyle={{
                    objectFit: 'cover',
                    alt: alt,
                    pointerEvents: 'none',
                  }}
                  style={{
                    width: '100%',
                    height: '100%',
                  }}
                ></Img>
              </div>
              <div dangerouslySetInnerHTML={{ __html: intro }}></div>
            </div>
            <div
              className="content-container"
              dangerouslySetInnerHTML={{ __html: content }}
            ></div>
          </Scrollbar>
        </div>
      </Div100vh>
    </>
  );
};

export default About;

export const AboutQuery = graphql`
  query AboutPageQuery {
    prismicAbout {
      data {
        intro {
          html
        }
        portrait {
          alt
          url
          localFile {
            childImageSharp {
              fixed(width: 1200) {
                ...GatsbyImageSharpFixed_withWebp_tracedSVG
              }
            }
          }
        }
        content {
          html
        }
        seo_title {
          text
        }
        title {
          text
        }
      }
    }
  }
`;
